import React, { Component } from 'react';
import styled from 'styled-components';
import imageUrlBuilder from '@sanity/image-url'
import client from '../client'
import constants from "../globals/constants";

const BlockContent = require('@sanity/block-content-to-react');


const ProjectCardSection = styled.section`
    margin: 2rem 3rem;
    border: 1px solid white;
    @media ${constants.device.containerWidth} {
        margin: 2rem 1rem;
    }
    &:first-child{
        margin:  0 3rem 2rem 3rem;
    }
    &:last-child{
        margin: 2rem 3rem 0 3rem;
    }

    .content {
        padding: 2rem 4rem;
        display: flex;
        @media ${constants.device.containerWidth} {
            flex-direction: column;
            margin: 0 auto;
            padding: 1rem 1.5rem;
        }

        @media ${constants.device.mobileL} {
            padding: 1rem .5rem;
        }

        .projectMedia{
            margin: 0 auto auto auto;
            @media ${constants.device.mobileXL} {
                img {
                    max-width: 100%;
                }
                iframe {
                    max-width: 100%;
                }
            }
            iframe {
                width: 442px;
                height: 442px;
                background-color: black;
            }

        }

        .text {
            display: flex;
            flex-direction: column;
            margin-left: 2rem;
            @media ${constants.device.containerWidth} {
                margin-left: 0rem;
                margin-top: 1.5rem;

            }
            .projectTitle {
                margin:0;
                color: white;
            }
            .projectBody {
                a {
                    position: relative;
                    transition: .2s ease-in-out;

                    &:hover, &:focus{
                        color: ${props => props.gradColor2};
                        &:before {
                            height:100%;

                        }
                    }

                    &:before {
                        z-index: -1;
                        content: '';
                        height:2px;
                        width: 100%;
                        background-color: white;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: .2s ease-in-out;


                    }
                }
            }

            .button {
                margin: auto 0 auto auto;
                display: flex;
                @media ${constants.device.tablet} {
                    flex-direction: column;
                }
                a {
                    background-color: rgba(0,0,0,0);
                    margin: auto 0;
                    border: 4px white solid;
                    border-radius: 32px;
                    padding: 0px 3rem;
                    transition: ease-in-out .2s
                    cursor: pointer;

                    &:hover, &:focus{
                        background-color: white;
                        color: ${props => props.gradColor2};
                    }
                }


            }

        }
    }
`;
const ProjectCardTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid white;

    @media ${constants.device.tablet} {
        flex-wrap: wrap;
        p {
            margin: 0 0 0 1rem;
        }
    }
    @media ${constants.device.mobileL} {
        padding: 1rem .5rem;
    }
    .innerLeft {
        display: flex;

    }
    .redDot, .yellowDot, .greenDot {
        border-radius: 50%;
        border: 1px solid white;
        max-height: 1rem;
        max-width: 1rem;
        min-height: 1rem;
        min-width: 1rem;
        height: 1rem;
        width: 1rem;
        margin: auto 0 auto 0;
        @media ${constants.device.tablet} {
            display: none;}

    }
    .redDot{; background-color: #FFBEBE;}
    .yellowDot{ margin: auto .5rem; background-color: #FFF49F;}
    .greenDot{ background-color: #B0FFAD;}

    p {
        margin: 0 1rem;
        @media ${constants.device.tablet} {
            margin: 0 1rem 0 0;
        }
    }
}
img{
    margin: auto .5rem;
    &:first-child {
        margin: auto 0.5rem auto 0;
    }

    @media ${constants.device.containerWidth} {
        margin: auto .1rem;
        &:first-child {
            margin: auto 0.1rem auto 0;
        }

    }
}

}
.innerRight{
    display: flex;
    img{
        margin: auto .5rem;
        &:last-child {
            margin: auto 0 auto 0.5rem;
        }
        @media ${constants.device.containerWidth} {
            margin: auto .1rem;
            &:last-child {
                margin: auto 0 auto 0.1rem;
            }

        }
    }





`;

const IconImg = styled.div`
    position: relative;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    &:after {
        content: '${props => props.icontitle}';
        opacity: 0;
        transition: .2s ease-in-out;
        position: absolute;
        bottom:100%;
        left:0;
    }

    &:hover {
        &:after {
            opacity: 1;
            padding: .5rem;
            background-color: white;
            color: ${props => props.gradColor2};
            z-index:999;
        }
    }
}

`;
const builder = imageUrlBuilder(client);

class ProjectCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            technologyState : [],
            platformsState : [],
        }
    }

    componentDidMount(){
        this.getData();
    }

    getData() {
        const {projectData} = this.props;

        const techQuery = '*[_type == "projectCase" && _id == $id][0]{categories[]->}';
        const platformQuery = '*[_type == "projectCase" && _id == $id][0]{platforms[]->}';
        const params = {id: projectData._id};

        client.fetch(techQuery, params).then(response => {
            this.setState({
                technologyState: response.categories,
            });
        });
        client.fetch(platformQuery, params).then(response => {
            this.setState({
                platformState: response.platforms,
            });
        });

    }

    buildImage(url, autoFormat) {
        const autoFormatImg = url ? builder
          .image(url)
          .auto('format')
          .url() : "";

        const constantSize = url ? builder
          .image(url)
          .height("442")
          .width("442")
          .url() : "";

        return autoFormat ? autoFormatImg : constantSize;
    }

    vimeoFrame (videoId) {
        const url = "https://player.vimeo.com/video/" + videoId;
        return (
          <iframe  src={url}  frameBorder="0"
                   allow="" allowFullScreen/>)
    }



    render() {
        const {projectData, gradColor2} = this.props;
        const {technologyState, platformState} = this.state;
        console.log(projectData);

        const platformItems = platformState ? platformState.map(platform => <IconImg icontitle={platform.title} gradColor2={gradColor2}><img key = {platform._id} src={this.buildImage(platform.platformIcon.asset)} alt={platform.title} iconTitle={platform.title}/></IconImg>): "";
        const techItems = technologyState ? technologyState.map(tech => <IconImg icontitle={tech.title} gradColor2={gradColor2}><img key = {tech._id} src={this.buildImage(tech.categoryIcon.asset, true)} alt={tech.title}/></IconImg>): "";

        return (
          <ProjectCardSection className="ProjectCard" gradColor2={gradColor2}>
              <ProjectCardTopBar>
                  <div className={"innerLeft"}>
                      <div className={"redDot"}/>
                      <div className={"yellowDot"}/>
                      <div className={"greenDot"}/>
                      <p>{projectData.title}</p>
                      {platformItems.length ? platformItems: ""}
                  </div>
                  <div className={"innerRight"}>
                      {techItems }

                  </div>
              </ProjectCardTopBar>
              <div className={"content"}>
                  <div className={"projectMedia"}>
                      {projectData.vimeoId ? this.vimeoFrame(projectData.vimeoId) : <img src={this.buildImage(projectData.mainImage.asset, false)} alt={projectData.title + " image"}/>}
                  </div>
                  <div className={"text"}>
                      <h2 className={"projectTitle"}>{projectData.subtitle}</h2>
                      <BlockContent className={"projectBody"} blocks={projectData.body} />
                      {projectData.btnText ? <div className={"button"}>
                          <a href={projectData.btnLink + ""} target={"blank"}>{projectData.btnText}</a>
                      </div>: "" }
                  </div>
              </div>
          </ProjectCardSection>

        );
    }
}

export default ProjectCard;
