import React, { Suspense, useEffect, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import {Environment, Html, useProgress} from "@react-three/drei"
import Model from "./Model"
import Overlay from "./Overlay"
import styled from "styled-components";
import {useState} from "react/cjs/react.production.min";


const CanvasDiv = styled.section`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
`



export default function ThreeDCanvas() {
  const overlay = useRef();
  const caption = useRef();
  const scroll = useRef(0);
  const currentIndex = useRef(0);
  function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress()
    return <Html center>{progress} % loaded</Html>
  }

  return (
    <CanvasDiv>
      <Canvas
        shadows
        onCreated={(state) => state.events.connect(overlay.current)}
        raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}>
        <ambientLight intensity={1} />
        <Suspense fallback={<Loader/>}>
          <Model scroll={scroll} currentIndex={currentIndex}/>
          <Environment preset="city" />
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} currentIndex={currentIndex} />
    </CanvasDiv>
  )
}
